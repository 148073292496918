<template>
  <div class="defaultEdit">
    <h2>Редактировать новость</h2>

    <ElemInput
      valueName="title"
      @changeValue="changeValue"
      :valueProp="title"
      label="Заголовок"
    />

    <ElemTextarea
      valueName="description"
      @changeValue="changeValue"
      :valueProp="description"
      label="Описание"
    />

    <button class="addTitleImage" @click="openPickImage = true" type="button">
      Титульное изображение
    </button>

    <img
      class="titleImage"
      v-if="ImageTitle"
      :src="baseUrl + 'storage/images/' + ImageTitle"
    />

    <PickGallery
      v-if="openPickImage"
      @close="openPickImage = false"
      @pickImage="pickImage"
    />
    
    <ElemSelect
      valueName="author"
      @changeValue="changeValue"
      :valueProp="author"
      label="Автор"
      :options="usersModels"
      optionValue="id"
      optionText="name"
    />
    <ElemSelect
      valueName="status"
      @changeValue="changeValue"
      :valueProp="status"
      label="Статус"
      :options="statusOptions"
    />

    <ElemSelect
      valueName="category"
      @changeValue="changeValue"
      :valueProp="category"
      label="Категория"
      :options="categoryOptions"
    />

    <TextEditor v-model:modelValue="content" />

    <button class="update" type="button" v-on:click="updateNews">
      Обновить
    </button>
    <button class="delete" type="button" v-on:click="deleteNews">
      Удалить
    </button>
  </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import PickGallery from "@/components/gallery/PickGallery";
import ElemInput from "@/components/elements/elemInput";
import ElemTextarea from "@/components/elements/elemTextarea";
import ElemSelect from "@/components/elements/elemSelect";
import news from "@/composables/views/news.ts";
import users from "@/composables/views/users.ts";


export default {
  setup() {
    const {
      updateNews,
      content,
      baseUrl,
      title,
      description,
      text,
      author,
      status,
      statusOptions,
      category,
      categoryOptions,
      ImageTitle,
      openPickImage,
      getNews,
      deleteNews,
    } = news();
    const { getUsers, usersModels } = users();
    return {
      updateNews,
      content,
      baseUrl,
      title,
      description,
      text,
      author,
      status,
      statusOptions,
      category,
      categoryOptions,
      ImageTitle,
      openPickImage,
      getNews,
      deleteNews,
      getUsers,
      usersModels,
    };
  },
  data() {
    return {};
  },

  components: {
    TextEditor,
    PickGallery,
    ElemInput,
    ElemTextarea,
    ElemSelect,
  },
  mounted: function () {},
  async created() {
    await this.getUsers();
    this.getNews();
  },

  methods: {
    changeValue(valueName, value) {
      this[valueName] = value;
    },
    pickImage(image) {
      this.ImageTitle = image;
      this.openPickImage = false;
    },
  },
};
</script>