<template>
  <div class="defaultUpload">
    <h2>Добавить гороскоп</h2>
    <ElemInput
      valueName="title"
      @changeValue="changeValue"
      :valueProp="title"
      label="Заголовок"
    />

    <ElemTextarea
      valueName="description"
      @changeValue="changeValue"
      :valueProp="description"
      label="Описание"
    />

    <ElemInput
      valueName="author"
      @changeValue="changeValue"
      :valueProp="author"
      label="Автор"
    />

    <ElemInput
      valueName="url"
      @changeValue="changeValue"
      :valueProp="url"
      label="Ссылка"
    />

    <ElemSelect
      valueName="type"
      @changeValue="changeValue"
      :valueProp="type"
      label="Категория"
      :options="typeOptions"
    />

    <span class="name">Текст</span>

    <TextEditor v-model="text" />

    <button type="button" v-on:click="createHoro">Загрузить</button>
  </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import ElemInput from "@/components/elements/elemInput";
import ElemTextarea from "@/components/elements/elemTextarea";
import ElemSelect from "@/components/elements/elemSelect";
import horo from "@/composables/views/horo.ts";

export default {
  setup() {
    const { title, description, text, type, typeOptions, createHoro, url } = horo();
    return {
      title,
      description,
      text,
      type,
      url,
      typeOptions,
      createHoro,
    };
  },
  data: () => ({}),
  components: {
    TextEditor,
    ElemInput,
    ElemTextarea,
    ElemSelect,
  },
  methods: {
    changeValue(valueName, value) {
      this[valueName] = value;
    },
    pickImage(image) {
      this.ImageTitle = image;
      this.openPickImage = false;
    },
  },
  created() {},
};
</script>