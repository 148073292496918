import Api from "@/api/news";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

export default function news() {
  let models = ref([]);
  let route = useRoute()
  let router = useRouter()
  let baseUrl = process.env.VUE_APP_API;
  let title = ref("");
  let description = ref("");
  let text = ref("");
  let author = ref("");
  let content = ref("");
  let status = ref("");
  let ImageTitle = ref("");
  let openPickImage = ref(false)
  let statusOptions = ref([
    { value: 0, text: "Главная" },
    { value: 1, text: "Обычная" },
  ]);
  let category = ref("");
  let categoryOptions = ref([
    { value: "Путешествия", text: "Путешествия" },
    { value: "Здоровье", text: "Здоровье" },
    { value: "Развлечения", text: "Развлечения" },
    { value: "Юмор", text: "Юмор" },
    { value: "Познавательное", text: "Познавательное" },
  ]);

  const sendNews = async () => {
    await Api.createModel({
      title: title.value,
      description: description.value,
      title_image: ImageTitle.value,
      text: content.value,
      author: author.value,
      status: status.value,
      category: category.value,
    });

    router.push("/news/");
  };

  const updateNews = async () => {
    await Api.updateModel(route.params.id, {
      id: route.params.id,
      description: description.value,
      title: title.value,
      text: content.value,
      author: author.value,
      status: status.value,
      title_image: ImageTitle.value,
      category: category.value,
    });

    alert("Новость успешно обновлена");
  }


  const deleteNews = async () => {
    let isDelete = confirm("Вы действительно хотите удалить новость?");

    if (isDelete === true) {
      await Api.deleteModel(route.params.id);
      router.push("/news/");
    }
  }


  const getNews = async () => {
    let response = await Api.getModel(route.params.id);
    title.value = response.data.title;
    content.value = response.data.text;
    description.value = response.data.description;
    author.value = response.data.author;
    status.value = response.data.status;
    ImageTitle.value = response.data.title_image;
    category.value = response.data.category;
  }
  // const changeValue = (valueName, value) => {
  //   values[valueName] = value;
  // };

  const getAllNews = async () => {
    let response = await Api.getModels({});
    models.value = response.data;
  }

  return {
    models, getAllNews, openPickImage, title, sendNews, updateNews, getNews, deleteNews, ImageTitle, content, baseUrl, description, text, author, status, statusOptions, category, categoryOptions
  };
}
