<template>
  <div class="defaultUpload">
    <h2>Добавить статью</h2>
    <ElemInput
      valueName="title"
      @changeValue="changeValue"
      :valueProp="title"
      label="Заголовок"
    />

    <ElemTextarea
      valueName="description"
      @changeValue="changeValue"
      :valueProp="description"
      label="Описание"
    />

    <button @click="openPickImage = true" type="button">
      Титульное изображение
    </button>

    <img
      class="titleImage"
      v-if="ImageTitle"
      :src="baseUrl + 'storage/images/' + ImageTitle"
    />

    <PickGallery v-if="openPickImage" @pickImage="pickImage" />

    <ElemSelect
      valueName="author"
      @changeValue="changeValue"
      :valueProp="author"
      label="Автор"
      :options="usersModels"
      optionValue="id"
      optionText="name"
    />

    <ElemSelect
      valueName="status"
      @changeValue="changeValue"
      :valueProp="status"
      label="Статус"
      :options="statusOptions"
    />

    <ElemSelect
      valueName="category"
      @changeValue="changeValue"
      :valueProp="category"
      label="Категория"
      :options="categoryOptions"
    />

    <span class="name">Текст</span>

    <TextEditor v-model="content" />

    <button type="button" v-on:click="sendNews">Загрузить</button>
  </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import PickGallery from "@/components/gallery/PickGallery";
import ElemInput from "@/components/elements/elemInput";
import ElemTextarea from "@/components/elements/elemTextarea";
import ElemSelect from "@/components/elements/elemSelect";
import news from "@/composables/views/news.ts";
import users from "@/composables/views/users.ts";

export default {
  setup() {
    const {
      sendNews,
      content,
      baseUrl,
      title,
      description,
      text,
      author,
      status,
      statusOptions,
      category,
      categoryOptions,
      ImageTitle,
      openPickImage,
    } = news();
    const { getUsers, usersModels } = users();
    return {
      sendNews,
      content,
      baseUrl,
      title,
      description,
      text,
      author,
      status,
      statusOptions,
      category,
      categoryOptions,
      ImageTitle,
      openPickImage,
      getUsers,
      usersModels,
    };
  },
  data: () => ({}),
  components: {
    TextEditor,
    PickGallery,
    ElemInput,
    ElemTextarea,
    ElemSelect,
  },
  methods: {
    changeValue(valueName, value) {
      this[valueName] = value;
    },
    pickImage(image) {
      this.ImageTitle = image;
      this.openPickImage = false;
    },
  },
  created() {
    this.getUsers();
  },
};
</script>