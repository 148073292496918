<template>
  <div :class="className + ' elemSelect'">
    <label :label-for="elId">
      {{ label }}
    </label>
    <select :disabled="disabled" :id="elId" v-model="value">
      <option
        v-for="(option, key) in options"
        :key="key"
        :value="option[optionValue]"
      >
        {{ option[optionText] }}
      </option>
    </select>
    <span class="error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: "elemSelect",
  props: {
    optionValue: {
      default: "value",
    },
    optionText: {
      default: "text",
    },
    disabled: {
      default: false,
    },
    elId: {
      default: "defaultInput",
    },
    label: {
      default: null,
    },
    error: {
      default: null,
    },
    valueName: {
      default: null,
    },
    valueProp: {
      default: null,
    },
    className: {
      default: null,
    },
    options: {
      default: [],
    },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    valueProp(value) {
      this.value = value;
    },
    value(value) {
      this.$emit("changeValue", this.valueName, value);
    },
  },
  methods: {},
  created() {
    this.value = this.valueProp;
  },
};
</script>
