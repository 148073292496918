<template>
  <div @mouseover="show_add" class="ImageGallery">
    <img @click="deleteImage(dates.id)" src="@/assets/cross.png" />
    <img :src="baseUrl + 'storage/images/' + dates.folder + '/' + dates.url" />
    <span>{{ dates.description }}</span>
  </div>
</template>
<script>
export default {
  props: ["dates"],
  data() {
    return {
      baseUrl: process.env.VUE_APP_API,
    };
  },

  methods: {
    show_add() {},
    deleteImage(id) {
      this.$emit("deleteImage", id);
    },
  },
};
</script>



<style scope>
.ImageGallery {
  position: relative;
  flex-flow: column nowrap;
  align-items: center;
  margin: 20px 28px 0px 0px;
  width: 260px;
  height: 220px;
  border: 1px solid #a5a0a0;
  border-radius: 5px;
  background: #e4e0e0;
}
.ImageGallery img:nth-of-type(1) {
  position: absolute;
  right: 8px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
}
.ImageGallery img:nth-of-type(2) {
  width: 250px;
  height: 167px;
  object-fit: cover;
  margin: 4px 0px 0px 0px;
}
.ImageGallery span {
  font: 16px/22px OpenSans-Semibold;
  margin: 7px auto 0px 5px;
  color: #a5a0a0;
}
</style>