<template>
  <div class="defaultView">
    <TopMenu page="Гороскопы" url="/horo/upload" @sort="sort" />
    <div class="list">
      <BlockView
        v-for="dates in models"
        v-bind:key="dates.id"
        v-bind:dates="dates"
        routeName="horo-edit"
      ></BlockView>
    </div>
  </div>
</template>

<script>
import BlockView from "@/components/BlockView.vue";
import TopMenu from "@/components/TopMenu.vue";
import horo from "@/composables/views/horo.ts";

export default {
  setup() {
    const { getAllHoro, models } = horo();
    return {
      getAllHoro,
      models,
    };
  },
  data() {
    return {};
  },
  name: "NewsView",

  components: {
    BlockView,
    TopMenu,
  },
  mounted: function () {},
  methods: {
    async sort(type) {
      console.log(type);
    },
  },
  async created() {
    this.getAllHoro();
  },
};
</script>
