import Api from "@/api/stories.js";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

export default function stories() {
    let models = ref([]);

    let route = useRoute()
    let router = useRouter()

    let baseUrl = process.env.VUE_APP_API;

    let title = ref("");
    let text = ref("");
    let author = ref("");
    let status = ref("");
    let description = ref("");
    let statusOptions = ref([
        { value: 1, text: "Активный" },
        { value: 0, text: "Не активный" },
    ]);

    const createStory = async () => {
        await Api.createModel({
            title: title.value,
            description: description.value,
            text: text.value,
            author: author.value,
            status: status.value,
        });

        router.push("/stories/");
    };

    const updateStory = async () => {
        await Api.updateModel(route.params.id, {
            title: title.value,
            description: description.value,
            text: text.value,
            author: author.value,
            status: status.value,
        });

        alert("Рассказ успешно обновлен");
    }


    const deleteStory = async () => {
        let isDelete = confirm("Вы действительно хотите удалить рассказ?");

        if (isDelete === true) {
            await Api.deleteModel(route.params.id);
            router.push("/stories/");
        }
    }


    const getStory = async () => {
        let response = await Api.getModel(route.params.id);

        title.value = response.data.title;
        text.value = response.data.text;
        description.value = response.data.description;
        author.value = response.data.author;
        status.value = response.data.status;
    }

    const getStories = async () => {
        let response = await Api.getModels({});
        models.value = response.data;
    }

    return {
        models, getStories, getStory, deleteStory, updateStory, createStory, baseUrl, title, description, text, author, status, statusOptions
    };
}
