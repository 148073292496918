import axios from 'axios'
// import store from '../store'
import store from '@/store'

class Axios {

    constructor(baseUrl, api) {
        this.client = axios.create({
            // baseURL: process.env.VUE_APP_PROTOKOL + ':' + process.env.VUE_APP_API,
            baseURL: process.env.VUE_APP_API + 'web/',
            timeout: process.env.NODE_ENV === 'production' ? 60000 : 0,
        });
        this.baseUrl = baseUrl;
        this.api = api;


        this.client.interceptors.response.use(response => {
            return response;
        }, (error) => {
            if (error.config && !error.config.__isRetryRequest) {
                if (error.response.status === 401) {
                    store.commit("logout");
                }
            }

            return Promise.reject(error);
        });



    }



    getHeaders() {
        // const token = store.getters.token;
        const authHeader = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        };

        return {
            ...authHeader
        };
    }

    async request(method, url, data) {
        let headers = this.getHeaders();
        return this.client({
            method,
            url,
            data,
            headers,
        });
    }

    async get(url, params) {
        let headers = this.getHeaders();
        return this.client.get(url, {
            params,
            headers,
        });
    }

    async post(url, data) {
        let headers = this.getHeaders();
        return this.client.post(url, data, { headers });
    }

    async put(url, data) {
        let headers = this.getHeaders();
        return this.client.put(url, data, { headers });
    }

    async delete(url) {
        let headers = this.getHeaders();
        return this.client.delete(url, { headers });
    }

    // Загрузка файла на сервер
    async upload(url, formData) {
        let headers = this.getHeaders();
        return this.client.post(url, formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    // Загрузка файла с сервера
    async download(url, params) {
        let headers = this.getHeaders();
        return this.client.get(url, {
            params,
            headers,
            responseType: 'blob'
        });

    }


}

export default Axios;