<template>
  <div class="defaultEdit">
    <h2>Редактировать гороскоп</h2>
    <ElemInput
      valueName="title"
      @changeValue="changeValue"
      :valueProp="title"
      label="Заголовок"
    />

    <ElemTextarea
      valueName="description"
      @changeValue="changeValue"
      :valueProp="description"
      label="Описание"
    />

    <ElemInput
      valueName="author"
      @changeValue="changeValue"
      :valueProp="author"
      label="Автор"
    />

    <ElemInput
      valueName="url"
      @changeValue="changeValue"
      :valueProp="url"
      label="Ссылка"
    />

    <ElemSelect
      valueName="type"
      @changeValue="changeValue"
      :valueProp="type"
      label="Категория"
      :options="typeOptions"
    />

    <span class="name">Текст</span>

    <TextEditor v-model="text" />

    <button class="update" type="button" v-on:click="updateHoro">
      Обновить
    </button>
    <button class="delete" type="button" v-on:click="deleteHoro">
      Удалить
    </button>
  </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import ElemInput from "@/components/elements/elemInput";
import ElemTextarea from "@/components/elements/elemTextarea";
import ElemSelect from "@/components/elements/elemSelect";
import horo from "@/composables/views/horo.ts";

export default {
  setup() {
    const {
      title,
      description,
      text,
      url,
      author,
      type,
      typeOptions,
      getHoro,
      updateHoro,
      deleteHoro,
    } = horo();
    return {
      title,
      description,
      text,
      url,
      author,
      type,
      typeOptions,
      getHoro,
      updateHoro,
      deleteHoro,
    };
  },
  data() {
    return {};
  },

  components: {
    TextEditor,
    ElemInput,
    ElemTextarea,
    ElemSelect,
  },
  mounted: function () {},
  async created() {
    this.getHoro();
  },

  methods: {
    changeValue(valueName, value) {
      this[valueName] = value;
    },
    pickImage(image) {
      this.ImageTitle = image;
      this.openPickImage = false;
    },
  },
};
</script>